import './styles.scss';

export const Input = ({ type, onChange, children }) => {
  return type === 'file' ? (
    <div>
      <label className="label">
        {children}
        <input
          style={{ display: 'none' }}
          type="file"
          id="input"
          accept="*"
          onChange={onChange}
        />
      </label>
    </div>
  ) : (
    <span />
  );
};
