import { useState } from 'react';
import * as XLSX from 'xlsx';

import { Input } from 'components/input';
import { Button } from 'components/button';

import './styles.scss';

const STATES_TO_FILTER = ['SC', 'NC', 'AL', 'GA', 'MS', 'LA', 'TX'];
const CITIES_TO_FILTER = [
  'Kannapolis',
  'Mebane',
  'Pleasant Hill',
  'Piedmont',
  'Braselton',
  'Cartersville',
  'Satsuma',
  'Tuscaloosa',
  'Jackson',
  ' Denham Springs',
  'Byron',
  'Breaux Bridge',
  'Charlotte',
  'Hammond',
  'Theodore',
  'San Antonio',
  'Duncan',
  'Atlanta',
  'Birmingham',
  'Houston',
  'Laredo',
  'Meridian',
  'Waynesville',
  'Temple',
  'Madison',
  'Newnan',
  'Orange',
  'Dallas',
  'Fort Worth',
  'Gaffney',
  'San Antonio',
  'Beasley',
  'Tyler',
  'Cotulla',
  'Lincoln',
  'Von Ormy',
  'Hempstead',
  'Moss Point',
  'Birmingham',
  'Hope Hull',
  'Carnesville',
  'Jackson',
  'Temple',
  'Greenwood',
  'Gulfport',
  'Pearl',
  'Graham',
  'Blacksburg',
  'Columbia',
  'Latta',
  'Rock Hill',
  'Baytown',
  'Dallas',
  'Houston',
  'Laredo',
  'Orange',
  'San Antonio',
  'Waco',
  'Brookshire',
  'Channelview',
  'Iowa',
  'Pasadena',
  'Schulenburg',
  'Marion',
  'Bunkie',
  'Lincoln',
  'Carnesville',
  'Jackson',
  'Kingsland',
  'Blacksburg',
  'Hardeeville',
  'Winnsboro',
  'Latta',
  'Conover',
  'Salisbury',
  'Monroe',
];

export const Formatter = () => {
  const [fileStructure, setFileStructure] = useState(null);
  const [fileInput, setFileInput] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  const onChangeFile = (e) => {
    setFileInput(e.target.files[0]);
    parseFile(e);
  };

  const parseFile = (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

      dataToObjects(dataParse);
    };
    reader.readAsBinaryString(file);
  };

  const dataToObjects = (fileParse) => {
    const fileParseCopy = [...fileParse];
    const fileColumns = fileParseCopy[5];

    const file = {
      header: fileParseCopy.slice(0, 5),
      columns: fileColumns,
      data: rebuildArraysToObjects(fileParseCopy.slice(6), fileColumns),
    };
    setFileStructure(file);

    setFilteredData(filterData(file.data));
  };

  const rebuildArraysToObjects = (arrays, objectProperties) => {
    const result = arrays.map((item) =>
      item.reduce(
        (acc, cur, index) => ({ ...acc, [objectProperties[index]]: cur }),
        {}
      )
    );

    return result;
  };

  const sortFromSmallestToLargestByProperty = (a, b, property) => a[property] - b[property];

  const filterData = (data) => {
    const dataCopy = [...data];

    const filteredByState = dataCopy.filter((item) =>
      STATES_TO_FILTER.some((state) => state === item.ST)
    );
    const filteredByCity = filteredByState.filter((item) =>
      CITIES_TO_FILTER.some(
        (city) => city.toLowerCase() === item.City.toLowerCase()
      )
    );
    const filteredByYourPrice = filteredByCity.sort((a, b) =>
      sortFromSmallestToLargestByProperty(a, b, 'Your Price')
    );
    const top50filteredByRetailPrice = filteredByYourPrice
      .slice(0, 50)
      .sort((a, b) =>
        sortFromSmallestToLargestByProperty(a, b, 'Retail Price')
      );
    const top20 = top50filteredByRetailPrice.slice(0, 20);

    return top20;
  };

  const exportFile = () => {
    const fileData = [
      ...fileStructure.header,
      [
        ...Object.values(fileStructure.columns).slice(0, 14),
        ...Object.values(fileStructure.columns).slice(14),
      ],
      ...filteredData.map((item) => {
        return [
          ...Object.values(item).slice(0, 14),
          ...Object.values(item).slice(14),
        ];
      }),
    ];

    const ws = XLSX.utils.aoa_to_sheet(fileData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Daily Price Quote - Better Of -');

    const splitedFileName = fileInput.name.split('.');
    const fileName = splitedFileName[0] + '-filtered.' + splitedFileName[1];

    XLSX.writeFile(wb, fileName);
  };

  return (
    <div className="container">
      <Input type="file" onChange={onChangeFile}>
        Select file
      </Input>
      <h3>Selected file {fileInput ? fileInput.name : '...'}</h3>
      <Button onClick={exportFile}>
        Download {fileInput ? fileInput.name : '...'}
      </Button>

      {fileStructure && (
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                {fileStructure.columns.map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredData &&
                filteredData.map((item, index) => (
                  <tr key={index}>
                    {Object.values(item).map((item, index) => (
                      <td key={index}>{item}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
