import { Formatter } from 'components/formatter';

import './App.scss';

const App = () => {
  return (
    <div className="App">
      <Formatter />
    </div>
  );
};

export default App;
